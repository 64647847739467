<template>
    <div class="Singletime" v-show="timeflage">
        <div class="timeslock">
           <div class="timesleft" ref="timelefts">
           <!-- <div v-for="(ele,index) in timesnewfen" :key="index" class="fen" :class="{'backGclor':index===overindex}" @click="overs(item,index)" @mouseover="overs(item,index)" @mouseout="outs"> -->
               <div v-for="(ele,index) in timesnewfen" :key="index" class="fen" :class="{'backGclor':index===overindex}" @click="overs(item,index)">
               {{ele}}
           </div>
           </div>
           <div class="timesright" ref="timerights">
            <!-- <div v-for="(elemiao,index) in timesnewmiao" :key="index" class="fen" :class="{'backGclor':index===overmiaoindex}"  @click="oversmiao(item,index)" @mouseover="oversmiao(item,index)"> -->
                <div v-for="(elemiao,index) in timesnewmiao" :key="index" class="fen" :class="{'backGclor':index===overmiaoindex}"  @click="oversmiao(item,index)">
               {{elemiao}}
           </div>
           </div>
        </div>
        <div><button @click="cancels">{{$t('system.cancel')}}</button><button @click="comfrims">{{$t('system.determine')}}</button></div>
    </div>
</template>
<script lang="ts">
import { reactive, ref, toRefs, defineComponent, onMounted, watch,nextTick } from "vue";
interface timesdurion  {
    timesnewfen:Array<any>;
    timesnewmiao:Array<any>;
    timeflage:boolean;
    overindex:any,
    overmiaoindex:any,
    defaultValuefen:null|number;
    defaultValuemiao:null|number;
}
// interface contentemit{
//     emit:(event:string,...args:unknown[])=>void
// }
import { ElMessage } from 'element-plus'
import i18n from "@/locales";
const _t = i18n.global.t;
export default defineComponent({
    emits:['timesvaluedurion'],
    setup(prop,content) {
      let timelefts = ref<any|HTMLElement>(null)
      let timerights = ref<any|HTMLElement>(null)
      const timedurion:timesdurion = reactive({
          timesnewfen:[],
          timesnewmiao:[],
          timeflage:false,
          overindex:1,
          overmiaoindex:0,
          defaultValuefen:1,
          defaultValuemiao:0
      })
      const methods = reactive({
          overs:(item:number|string,index:number)=>{
              timedurion.overindex = index
            //   timelefts.value.scrollTop = index *15
            //   console.log(timelefts.value.scrollTop)
          },
          oversmiao:(item:number|string,index:number)=>{
              timedurion.overmiaoindex = index
          },
          outs:()=>{
          },
          cancels(){
             timedurion.timeflage = false
             timedurion.overindex =  timedurion.defaultValuefen
             timedurion.overmiaoindex = timedurion.defaultValuemiao
          },
          comfrims(){
            if (timedurion.overindex===0&&timedurion.overmiaoindex === 0) {
                 ElMessage({
                  message:_t('disinfect.xssjbwl')
                })
                return
            }
            timedurion.timeflage = false
            console.log(timedurion.overindex,timedurion.overmiaoindex)
            content.emit('timesvaluedurion',timedurion.overindex,timedurion.overmiaoindex)
            // timelefts.value.scrollTop = timedurion.overindex *15
            timedurion.defaultValuefen = timedurion.overindex
            timedurion.defaultValuemiao = timedurion.overmiaoindex
          },
          setleftscrlotop(values:number) {
              timelefts.value.scrollTop = (20 * values) - 40
              console.log(timelefts.value.scrollTop)
          },
          setrightscrlotop(values:number) {
              timerights.value.scrollTop = (20 * values) - 40
              console.log(timerights.value.scrollTop)
          }
      })
      onMounted(()=>{
          for(let i=0;i<31;i++){
              timedurion.timesnewfen.push(i<10?'0'+i:i)
          }
          for(let ii=0;ii<60;ii++){
              timedurion.timesnewmiao.push(ii<10?'0'+ii:ii)
          }
        nextTick(()=>{
            // timedurion.overindex = 0
            // timedurion.defaultValuefen = 0
            // timedurion.overmiaoindex = 5
            // timedurion.defaultValuemiao = 5
            // timelefts.value.scrollTop= 0
            console.log(timelefts.value.scrollTop)
            // timelefts.value.style.scrollBarArrowColor = 'red'
             watch(timedurion,(newold:any)=>{
             methods.setleftscrlotop(newold.overindex)
             methods.setrightscrlotop(newold.overmiaoindex)
            },
            {deep:true},
            )
        })
        //  timelefts.value.scrollTop = 1200
        // console.log(timelefts.value.scrollTop)
      })
      return {
          ...toRefs(timedurion),
          ...toRefs(methods),
          timelefts,
          timerights
      }
    }
})
</script>
<style lang="scss" scoped>
.Singletime{
position: absolute;
z-index: 9999;
.backGclor{
    background: rgba(0,0,0,0.3);
}
.timeslock{
 background: #fff;
 display: flex;
 width: 200px;
 .timesleft,.timesright{
     height: 200px;
     overflow: auto;
     width: 49%;
     text-align: center;
     border-radius: 4px;
     border: 1px solid #DCDFE6;
 }
 .fen{
     height: 20px;
     line-height: 20px;
 }
}
}
</style>