<template>
    <div class="createstask">
        <el-breadcrumb separator="/" class="breadcrumb">
            <el-breadcrumb-item @click="godashboards" class="needGo">
                {{ $t('route.robot') }}
            </el-breadcrumb-item>
            <el-breadcrumb-item @click="gotorobotsn" class="needGo">
                {{ $t('robot.detail') }}
            </el-breadcrumb-item>
            <el-breadcrumb-item>{{ $t('creted.bjdsrw') }}</el-breadcrumb-item>
        </el-breadcrumb>
        <el-breadcrumb separator="/" class="breadcrumb">
            <el-breadcrumb-item>{{ $t('creted.bjdsrw') }}</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- <div class="createdtop">创建任务</div> -->
        <div class="contentWrapper robotDetail">
            <div class="">{{ $t('disinfect.room') }}:</div>
            <div class="Uniformmargmintop">
                <el-cascader v-model="value" :options="options" @change="handleChange" size="mini" style="width: 300px;" />
            </div>
            <div class="Uniformmargtop" v-show="roomsobj.roomid">{{ $t('disinfect.pattern') }}:</div>
            <div class="Uniformmargmintop" v-show="roomsobj.roomid">
                <el-radio v-model="radio1" label="deploy_disinfect" v-show="roomsobj.mapnamas">
                    {{ $t('disinfect.existingMap') }}
                </el-radio>
                <el-radio v-model="radio1" label="explore_disinfect">
                    {{ $t('creted.zztansuo') }}
                </el-radio>
                <!-- <el-radio v-model="radio1" label="explore_disinfect">自主探索</el-radio> -->
            </div>
            <div class="Uniformmargtop" v-show="radio1">
                {{ $t('user.operator') }}
            </div>
            <div class="addlistoperator" v-show="radio1" @click="addoperatorflge = !addoperatorflge">
                {{ operatorname }}
                <img
                    class="opertorimages"
                    :class="{
                        arrowTransform: addoperatorflge,
                        arrowTransformReturn: !addoperatorflge
                    }"
                    src="@/assets/images/robot/xiala.png"
                />
                <div class="addoperator" v-show="addoperatorflge">
                    <div class="modoals">
                        <div
                            class="boxdiv"
                            v-for="(ele, index) in opertorlist"
                            :key="index"
                            :style="{
                                color: ele.negative != 1 ? (ele.username == operatorname || ele.email == operatorname ? '#1890FF' : '') : 'rgba(0,0,0,0.2)',
                                cursor: ele.negative != 1 ? '' : 'no-drop'
                            }"
                            @click.stop="setusernames(ele)"
                        >
                            {{ ele.username == '' ? ele.email : ele.username }}
                        </div>
                    </div>
                    <div class="addbtn" @click="opeatorfalge = true" v-show="store.state.user.authority == 1">+ {{ $t('user.adduser') }}</div>
                </div>
            </div>
            <div class="Uniformmargtop" v-show="radio1 === 'explore_disinfect'">{{ $t('creted.ddxssj') }}:</div>
            <div class="Uniformmargmintop" v-show="radio1 === 'explore_disinfect'">
                <div class="timesclass" @click="killtrue">{{ exploresion }}</div>
                <Killtime ref="Killtimes" @timesvaluedurion="timesvaluedurion" :timeoverfenindex="timeoverfenindex" />
            </div>
            <div class="Uniformmargtop" v-show="radio1 === 'deploy_disinfect' && getflooron">
                梯控楼层:
            </div>
            <div class="Uniformmargmintop" v-show="radio1 === 'deploy_disinfect' && getflooron">
                <el-select v-model="getfloor" placeholder="1" size="mini" @change="setfloorcs" style="width:300px">
                    <el-option v-for="item in getfloorarray" :key="item" :label="item" :value="item" />
                </el-select>
            </div>
            <div class="Uniformmargtop" v-show="radio1">{{ $t('creted.aqjc') }}:</div>
            <div class="Uniformmargmintop" v-show="radio1">
                <el-checkbox v-model="checked1">
                    {{ $t('creted.mjp') }}
                </el-checkbox>
                <el-checkbox v-model="checked2">
                    PIR
                </el-checkbox>
            </div>
            <div class="Uniformmargtop" v-show="radio1 && radio1 !== 'disinfect'">{{ $t('creted.openuv') }}:</div>
            <div class="Uniformmargmintop" v-show="radio1 && radio1 !== 'disinfect'">
                <el-radio v-model="mode" :label="1">{{ $t('creted.openuvOn') }}</el-radio>
                <el-radio v-model="mode" :label="2">{{ $t('creted.openuvOff') }}</el-radio>
                <div :style="{ color: 'red', 'margin-top': '6px' }">
                    {{ $t('creted.openuvwarning') }}
                </div>
            </div>
            <div class="Uniformmargtop" v-show="radio1">{{ $t('robot.countdown') }}:</div>
            <div class="Uniformmargmintop" v-show="radio1">
                <el-select v-model="countdown" placeholder="20s" size="mini" @change="countselet" style="width:300px">
                    <el-option v-for="item in selectcountdown" :key="item" :label="item" :value="item" />
                </el-select>
            </div>
            <div class="Uniformmargtop" v-show="radio1">{{ $t('creted.bdzthf') }}:</div>
            <div class="Uniformmargmintop" v-show="radio1">
                <el-select v-model="Theinvasion" placeholder="20s" size="mini" @change="Theinvasionselet" style="width:300px">
                    <el-option v-for="item in selectTheinvasion" :key="item" :label="item" :value="item" />
                </el-select>
            </div>
            <div class="Uniformmargtop" v-show="radio1">{{ $t('creted.rwlx') }}:</div>
            <div class="Uniformmargmintop" v-show="radio1">
                <el-radio v-model="radio2" label="once">
                    {{ $t('creted.dcrw') }}
                </el-radio>
                <el-radio v-model="radio2" label="week">
                    {{ $t('creted.cfrw') }}
                </el-radio>
            </div>
            <div class="Uniformmargtop" v-show="radio1">
                {{ radio2 === 'once' ? $t('creted.xzrq') : $t('creted.cfgh') }}
            </div>
            <div v-show="radio1 && radio2 === 'once'" class="Uniformmargmintop">
                <el-date-picker v-model="value1" size="mini" type="date" :clearable="false" :editable="false" @change="getnedatefn" style="width:300px" />
            </div>
            <div v-show="radio1 && radio2 != 'once'" class="Uniformmargmintop">
                <el-select v-model="Datevalue1" size="mini" multiple :placeholder="$t('creted.qxzcfsj')" @change="selectdata" style="width:300px">
                    <el-option v-for="item in AlternativeDate" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
            </div>
            <div class="Uniformmargtop" v-show="radio1">
                {{ radio2 === 'once' ? $t('creted.xzsj') : $t('creted.cfsj') }}
            </div>
            <div v-show="radio1" class="Uniformmargmintop">
                <el-time-picker v-model="value2" size="mini" :clearable="false" :editable="false" @change="getfenzhongfn" style="width:300px" />
            </div>
            <div class="Uniformmargtop" v-show="radio1">
                <el-button
                    @click="stabilization(newtasks)"
                    class="edtbutton"
                    :class="{ noedit: !falge }"
                    @keyup.space.prevent.native
                    @keydown.enter.prevent.native
                >
                    {{ $t('creted.bcxg') }}
                </el-button>
                <el-button @click="gotorobot" @keyup.space.prevent.native @keydown.enter.prevent.native>
                    {{ $t('system.cancel') }}
                </el-button>
            </div>
        </div>
        <el-dialog :title="$t('user.adduser')" v-model="opeatorfalge" custom-class="oneUser" @close="cancelDialog">
            <div>
                <el-input v-model="addopeatorsname" :placeholder="$t('system.password.pleaseEnter')" />
            </div>
            <div :style="{ margin: '10px 0', color: 'rgba(0,0,0,0.4)' }">{{ $t('user.createrdoperatoruser') }}</div>
            <div :style="{ textAlign: 'right' }">
                <el-button @keyup.space.prevent.native @keydown.enter.prevent.native @click="cancelDialog">{{ $t('system.cancel') }}</el-button>
                <el-button type="primary" @click="addUseropertaor" @keyup.space.prevent.native @keydown.enter.prevent.native>
                    {{ $t('system.determine') }}
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script lang="ts">
import { reactive, ref, toRefs, defineComponent, onMounted, watch, nextTick } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Killtime from '@/components/killtime/index.vue';
import {
    regionList,
    delRegion,
    editRegion,
    createRegion,
    regionInfo,
    roomList,
    deletetaskrw,
    createTasks,
    Operationtasks,
    newroomlist,
    taskiddetail,
    tasksedit,
    robotelevator
} from '@/apis/region';
import internal from 'stream';
import { useStore } from '@/store';
import { getAllUser, addUser } from '@/apis/user';
import Moment from 'moment';
import { ElMessage } from 'element-plus';
import { debounce, last, throttle } from 'lodash';
import i18n from '@/locales';
import { number } from 'echarts';
// const newdate = new  Date()
interface timingfaces {
    paramshttp?: Object;
    //定义点击房间后传递的字段为对象
    roomsobj: any;
    options: Array<any>;
    radio1: String;
    checked1: Boolean;
    checked2: Boolean;
    countdown: any;
    Theinvasion: any;
    selectcountdown: Array<String>;
    selectTheinvasion: Array<String>;
    explorelist: Array<String>;
    num: Number;
    explonum: number;
    radio2: String;
    value1: any;
    value2: any;
    Datevalue1: Array<any>;
    sn: any;
    weekdays: Array<String>;
    value: any;
    taskid: any;
    AlternativeDate: any;
    exploresion: string | number;
    falge: any;
    timeoverfenindex: number | null;
    getfloor: number;
    getfloorarray: Array<number>;
    getflooron: boolean;
    mode: number;
    addoperatorflge: boolean;
    operatorname: any;
    opertorlist: Array<any>;
    opeatorfalge: boolean;
    addopeatorsname: string;
}
let newtimingstates: any = null;
export default defineComponent({
    components: {
        Killtime
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const timingstates: timingfaces = reactive({
            sn: route.params.sn,
            paramshttp: {
                page: 1,
                pageSize: 100,
                limit: true,
                areaName: ''
            },
            value: [],
            options: [],
            roomsobj: {},
            radio1: '',
            checked1: false,
            checked2: false,
            Datevalue1: [],
            AlternativeDate: [
                {
                    value: '0',
                    label: i18n.global.t('creted.zhouri')
                },
                {
                    value: '1',
                    label: i18n.global.t('creted.zhouyi')
                },
                {
                    value: '2',
                    label: i18n.global.t('creted.zhouer')
                },
                {
                    value: '3',
                    label: i18n.global.t('creted.zhousan')
                },
                {
                    value: '4',
                    label: i18n.global.t('creted.zhousi')
                },
                {
                    value: '5',
                    label: i18n.global.t('creted.zhouwu')
                },
                {
                    value: '6',
                    label: i18n.global.t('creted.zhouliu')
                }
            ],
            selectcountdown: ['5s', '10s', '15s', '20s', '25s', '30s'],
            countdown: '20s',
            selectTheinvasion: ['1min', '3min', '5min', '10min'],
            explorelist: ['30s', '1min', '2min', '3min', '5min'],
            exploresion: '',
            Theinvasion: '5min',
            num: 5,
            explonum: 30,
            radio2: 'once',
            value2: new Date(new Date().setHours(new Date().getHours() + 1)),
            value1: new Date(new Date().setHours(new Date().getHours() + 1)),
            weekdays: [],
            taskid: '',
            falge: false,
            timeoverfenindex: null,
            getfloor: 1,
            getfloorarray: [],
            getflooron: true,
            mode: 1,
            addoperatorflge: false,
            opertorlist: [],
            operatorname: store.state.user.name,
            opeatorfalge: false,
            addopeatorsname: ''
            // Gotothetop() {
            //     router.go(-1)
            // }
        });
        const methods = reactive({
            getusers() {
                getAllUser({
                    userName: '',
                    authority: '',
                    negative: '',
                    pageSize: 1000,
                    page: 1
                }).then((res: any) => {
                    console.log(res);
                    timingstates.opertorlist = res.data.lists;
                });
            },
            // 新增操作人按钮确认
            addUseropertaor() {
                if (timingstates.addopeatorsname.length < 6 || timingstates.addopeatorsname.length > 20) {
                    ElMessage({
                        message: i18n.global.t('user.usernameRule2'),
                        type: 'error'
                    });
                    return;
                }
                addUser({ username: timingstates.addopeatorsname, password: '123456' }).then((res: any) => {
                    console.log(res);
                    if (res.code == 200) {
                        timingstates.opeatorfalge = false;
                        timingstates.operatorname = timingstates.addopeatorsname;
                        timingstates.addopeatorsname = '';
                        methods.getusers();
                        ElMessage({
                            message: i18n.global.t('system.createSuccess'),
                            type: 'success'
                        });
                    } else if (res.code == 100013) {
                        ElMessage({
                            message: i18n.global.t('user.repeatUser'),
                            type: 'error'
                        });
                    }
                });
            },
            // 取消新增操作人
            cancelDialog() {
                timingstates.opeatorfalge = false;
                timingstates.addopeatorsname = '';
            },
            // 点击后设置操作人
            setusernames(ele: any) {
                if (ele.negative == 1) {
                    return;
                }
                timingstates.operatorname = ele.username == '' ? ele.email : ele.username;
                timingstates.addoperatorflge = false;
            },
            getelevator(ele: string) {
                robotelevator({
                    sn: timingstates.sn,
                    name: ele
                }).then((res: any) => {
                    console.log(JSON.parse(res.data));
                    let ress = JSON.parse(res.data);
                    if (ele === 'ele_floor') {
                        methods.getfloorsmax(Number(ress.content.value));
                    } else {
                        timingstates.getflooron = ress.content.value === 'on' ? true : false;
                    }
                });
            },
            godashboards() {
                router.push('/dashboard');
            },
            gotorobotsn() {
                router.push('/robot/robotInfo/' + timingstates.sn);
            },
            killtrue(): void {
                Killtimes.value.timeflage = true;
            },
            timesvaluedurion(value1: number, value2: number) {
                timingstates.explonum = value1 * 60 + value2;
                timingstates.exploresion = value1 + 'min' + value2 + 'S';
                console.log(timingstates.explonum);
            },
            // 获取区域及区域下的所有房间 手动添加上value 和label字段
            getquyus: (): void => {
                regionList(timingstates.paramshttp).then((res: any) => {
                    // console.log(res.data.lists);
                    timingstates['options'] = res.data.lists;
                    timingstates['options'].forEach((ele: any, index: number) => {
                        timingstates['options'][index].value = timingstates['options'][index].ID;
                        timingstates['options'][index].label = timingstates['options'][index].area_name;
                        newroomlist({
                            page: 0,
                            pageSize: 100,
                            sn: timingstates.sn,
                            areaid: timingstates['options'][index].ID
                        }).then((ress: any) => {
                            console.log(JSON.parse(ress.data));
                            timingstates['options'][index]['children'] = [];
                            if (JSON.parse(ress.data).content.list.length > 0) {
                                JSON.parse(ress.data).content.list.forEach((eless: any, indexs: number) => {
                                    timingstates['options'][index]['children'][indexs] = eless;
                                    timingstates['options'][index]['children'][indexs].label = eless.name;
                                    timingstates['options'][index]['children'][indexs].value = {
                                        roomid: eless.area_id === 0 ? eless.id : eless.yun_id,
                                        areaid: eless.area_id,
                                        mapnamas: route.params.type == 'explore_disinfect' ? '' : eless.map
                                    };
                                });
                            }
                        });
                    });
                });
            },
            // 点击切换倒计时
            countselet: (values: string) => {
                console.log(values);
                timingstates.countdown = values;
            },
            // 点击切换探索模式下时间
            exploresionselet: (explorevalue: string) => {
                console.log(explorevalue);
                if (parseInt(explorevalue) != 30) {
                    timingstates.explonum = parseInt(explorevalue) * 60;
                } else {
                    timingstates.explonum = parseInt(explorevalue);
                }
            },
            exploresionbianji: (eles: number): string => {
                // if (eles != 30) {
                //   return eles/60 +'min'
                // } else {
                //   return eles + 's'
                // }
                Killtimes.value.overindex = Math.floor(eles / 60);
                Killtimes.value.overmiaoindex = eles % 60;
                Killtimes.value.defaultValuefen = Math.floor(eles / 60);
                Killtimes.value.defaultValuemiao = eles % 60;
                timingstates.timeoverfenindex = Math.floor(eles / 60);
                // console.log(Killtimes.value.timelefts.scrollTop,Killtimes.value.overindex*15)
                return Math.floor(eles / 60) + 'min' + (eles % 60) + 's';
            },
            Theinvasionselet: (values: string) => {
                console.log(values);
                timingstates.Theinvasion = values;
            },
            // 选择房间后判断
            handleChange: (value: any): void => {
                console.log(value, timingstates.value);
                // states .roomsobj  = {ajda:1}
                if (!value[1]) {
                    ElMessage({
                        message: i18n.global.t('disinfect.dqmyfj')
                    });
                } else {
                    timingstates.roomsobj = value[1];
                }
            },
            // raiofn: (vlues) => {
            //   console.log(vlues, states.radio1);
            // },
            getnedatefn: (getriqi: any): void => {
                console.log(getriqi, Moment(getriqi).format('YYYY-MM-DD'));
                timingstates.value1 = getriqi;
            },
            getfenzhongfn: (getriqi: any): void => {
                console.log(getriqi, Moment(getriqi).format('HH:mm:ss'));
                timingstates.value2 = getriqi;
            },
            selectdata: (values: any) => {
                console.log(values);
                timingstates.weekdays = values;
            },
            stabilization: debounce((Needtooffer) => Needtooffer(), 2000, { leading: true, trailing: false }),
            newtasks: () => {
                if (!timingstates.falge) {
                    return;
                }
                if (timingstates.radio2 != 'once' && timingstates.weekdays.length <= 0) {
                    ElMessage({
                        message: i18n.global.t('creted.qxzcfsj')
                    });
                    return;
                }
                const restask: Object = {
                    mode: timingstates.mode,
                    area_id: timingstates.roomsobj.areaid,
                    auto_finish: parseInt(timingstates.Theinvasion) * 60,
                    countdown: parseInt(timingstates.countdown),
                    cycle: timingstates.radio2,
                    days: timingstates.radio2 === 'once' ? [] : Array.from(timingstates.weekdays),
                    duration: timingstates.radio1 === 'explore_disinfect' ? timingstates.explonum : null,
                    flags: (timingstates.checked1 ? 1 : 0) | (timingstates.checked2 ? 2 : 0),
                    map_name: timingstates.radio1 === 'deploy_disinfect' ? timingstates.roomsobj.mapnamas : '',
                    room_id: timingstates.roomsobj.roomid,
                    type: timingstates.radio1,
                    time: Moment(timingstates.value2).format('HH:mm:ss'),
                    date:
                        timingstates.radio2 === 'once'
                            ? Moment(timingstates.value1).format('YYYY-MM-DD')
                            : Moment(new Date(new Date().setHours(new Date().getHours() + 1))).format('YYYY-MM-DD'),
                    destination: String(timingstates.getfloor),
                    operator: timingstates.operatorname
                };
                console.log(restask);
                tasksedit(timingstates.taskid, route.params.sn, restask).then((res: any) => {
                    // console.log(res,'修改数据')
                    if (JSON.parse(res.data).content.code === 0) {
                        ElMessage({
                            message: i18n.global.t('disinfect.xgdsrwcgqsh'),
                            type: 'success'
                        });
                        router.replace(`/robot/robotInfo/${route.params.sn}`);
                    } else if (JSON.parse(res.data).content.code === 11010031) {
                        ElMessage({
                            message: i18n.global.t('creted.mjpwljcjsb')
                        });
                    } else if (JSON.parse(res.data).content.code === 11010023) {
                        ElMessage({
                            message: i18n.global.t('creted.dqyjxrw')
                        });
                    } else if (JSON.parse(res.data).content.code === 11010028) {
                        ElMessage({
                            message: i18n.global.t('creted.xtlbcwcjsb')
                        });
                    } else if (JSON.parse(res.data).content.code === 11010032) {
                        ElMessage({
                            message: i18n.global.t('creted.sbgzcjsb')
                        });
                    } else if (JSON.parse(res.data).content.code === 11010030) {
                        ElMessage({
                            message: i18n.global.t('creted.jqjtqbcan')
                        });
                    } else if (JSON.parse(res.data).content.code === 11010033) {
                        ElMessage({
                            message: i18n.global.t('creted.dcyccjsb')
                        });
                    } else if (JSON.parse(res.data).content.code === 11010034) {
                        ElMessage({
                            message: i18n.global.t('creted.fztyccjsb')
                        });
                    } else if (JSON.parse(res.data).content.code === 11010035) {
                        ElMessage({
                            message: i18n.global.t('creted.dpdjglcjsb')
                        });
                    } else if (JSON.parse(res.data).content.code === 11010036) {
                        ElMessage({
                            message: i18n.global.t('creted.zdyccjsb')
                        });
                    } else if (JSON.parse(res.data).content.code === 11010037) {
                        ElMessage({
                            message: i18n.global.t('creted.zczcjsb')
                        });
                    } else if (JSON.parse(res.data).content.code === 11010038) {
                        ElMessage({
                            message: i18n.global.t('creted.xczcjsb')
                        });
                    } else if (JSON.parse(res.data).content.code === 11010042) {
                        ElMessage({
                            message: i18n.global.t('creted.dqdtbczwfcj')
                        });
                    } else if (JSON.parse(res.data).content.code === 11010045) {
                        ElMessage({
                            message: i18n.global.t('creted.dhmkyxzcjsb')
                        });
                    } else if (JSON.parse(res.data).content.code === 11010046) {
                        ElMessage({
                            message: i18n.global.t('creted.cgqyccjsb')
                        });
                    } else if (JSON.parse(res.data).content.code === 11010048) {
                        ElMessage({
                            message: i18n.global.t('creted.jqrwdggcjsb')
                        });
                    } else if (JSON.parse(res.data).content.code === 11010049) {
                        ElMessage({
                            message: i18n.global.t('creted.jqrdlxy')
                        });
                    } else if (JSON.parse(res.data).content.code === 11010040) {
                        ElMessage({
                            message: i18n.global.t('dsrw.rwsjct')
                        });
                    } else if (JSON.parse(res.data).content.code === 11010041) {
                        ElMessage({
                            message: i18n.global.t('dsrw.rwjgq')
                        });
                    } else if (JSON.parse(res.data).content.code === 11010014) {
                        ElMessage({
                            message: i18n.global.t('dsrw.cjsb')
                        });
                    } else {
                    }
                });
            },
            gettaskdetail: () => {
                taskiddetail({
                    sn: route.params.sn,
                    name: route.params.taskid
                }).then((res: any) => {
                    console.log(res);
                    // methods.handleChange([1,2])
                    let datas = JSON.parse(res.data).content.info;
                    console.log(datas);
                    timingstates.mode = datas.mode;
                    timingstates.value[0] = datas.area_id;
                    timingstates.value[1] = {
                        roomid: datas.room_id,
                        areaid: datas.area_id,
                        mapnamas: datas.type === 'deploy_disinfect' ? datas.map_name : ''
                    };
                    timingstates.roomsobj = timingstates.value[1];
                    console.log(timingstates.value);
                    timingstates.Theinvasion = Number(datas.auto_finish_timeout) / 60 + 'min';
                    timingstates.countdown = datas.countdown + 's';
                    timingstates.radio2 = datas.cycle === 'day' ? 'week' : datas.cycle;
                    timingstates.operatorname = datas.operator;
                    // console.log( timingstates.radio2)
                    timingstates.radio1 = datas.type;
                    if (datas.cycle === 'day') {
                        // datas.days = [0,1,2,3,4,5,6]
                        // timingstates.weekdays = datas.days.map(String)
                        // timingstates.Datevalue1 = datas.days.map(String)
                        timingstates.weekdays = ['0', '1', '2', '3', '4', '5', '6'];
                        timingstates.Datevalue1 = ['0', '1', '2', '3', '4', '5', '6'];
                    } else {
                        timingstates.weekdays = timingstates.radio2 === 'once' ? [] : datas.days.map(String);
                        timingstates.Datevalue1 = timingstates.radio2 === 'once' ? [] : datas.days.map(String);
                    }
                    // timingstates.weekdays = timingstates.radio2 === 'once'?[]:datas.days.map(String)
                    // timingstates.Datevalue1 =  timingstates.radio2 === 'once'?[]:datas.days.map(String)
                    // timingstates.weekdays = res.data.days!=''?res.data.days.split(','):[]
                    // timingstates.Datevalue1 = res.data.days!=''?res.data.days.split(','):[]
                    timingstates.checked1 = datas.flags === 1 || datas.flags === 3 ? true : false;
                    timingstates.checked2 = datas.flags === 2 || datas.flags === 3 ? true : false;
                    timingstates.radio1 === 'explore_disinfect' ? (timingstates.explonum = datas.duration) : '';
                    timingstates.radio1 === 'explore_disinfect' ? (timingstates.exploresion = methods.exploresionbianji(datas.duration)) : '';
                    // console.log(timingstates.explonum, timingstates.radio1==='explore_disinfect',res.data.duration)
                    timingstates.value2 = new Date(datas.date + ' ' + datas.time);
                    timingstates.taskid = datas.name;
                    timingstates.getfloor = datas.config.elevator_item
                        ? Number(datas.config.elevator_item.floor_name)
                        : Number(datas.config.elevatorItem.floorName);
                    // timingstates.value1 = datas.cycle==='week'||datas.cycle==='day'?new Date(new Date().setHours(new Date().getHours() + 1)):new Date(datas.date+ ' ' +datas.time)
                    timingstates.value1 =
                        timingstates.radio2 === 'week' ? new Date(new Date().setHours(new Date().getHours() + 1)) : new Date(datas.date + ' ' + datas.time);
                    newtimingstates = JSON.parse(JSON.stringify(timingstates));
                });
            },
            gotorobot: () => {
                router.back();
            },
            getfloorsmax(valsa: number) {
                for (let i = 1; i <= valsa; i++) {
                    timingstates.getfloorarray.push(i);
                }
            },
            setfloorcs(values: number) {
                timingstates.getfloor = values;
                console.log(timingstates.getfloor);
            }
        });
        let Killtimes = ref<any | HTMLElement>(null);
        onMounted(() => {
            //调用区域
            methods.getquyus();
            methods.getelevator('elevator');
            methods.getelevator('ele_floor');
            //   let t = methods.createTimeStr(new Date(), 'ymd') + ' 00:03:00'
            // timingstates.value2 = new Date(new Date().setHours(new Date().getHours() + 1));
            // timingstates.value1 = new Date(new Date().setHours(new Date().getHours() + 1))
            methods.gettaskdetail();
            methods.getusers();
            setTimeout(() => {
                watch(
                    timingstates,
                    (newold) => {
                        if (newold != newtimingstates && newtimingstates != null) {
                            timingstates.falge = true;
                            console.log(timingstates.falge, '1');
                        } else {
                            timingstates.falge = false;
                            console.log(timingstates.falge, '2');
                        }
                    },
                    { deep: true }
                );
            }, 1000);
            nextTick(() => {
                // console.log(Killtimes.value.timeflage)
                // states.explonum = Killtimes.value.overindex*60+Killtimes.value.overmiaoindex
                // states.exploresion = Killtimes.value.overindex +'min' +Killtimes.value.overmiaoindex+'S'
            });
        });
        return {
            ...toRefs(timingstates),
            ...toRefs(methods),
            store,
            debounce,
            Killtimes
        };
    }
});
</script>
<style lang="scss" scoped>
.edtbutton {
    background: rgba(24, 144, 255);
    color: #fff;
}

.noedit {
    background: rgba(24, 144, 255, 0.6);
}

.robotDetail {
    position: relative;
    min-height: 50px;
    max-height: 100%;
    padding-left: 340px;
    font-size: 12px;

    .Uniformmargtop {
        margin-top: 25px;
    }

    .Uniformmargmintop {
        margin-top: 10px;

        .timesclass {
            height: 30px;
            line-height: 30px;
            width: 300px;
            padding-left: 15px;
            color: #606266;
            border-radius: 4px;
            border: 1px solid #dcdfe6;
        }
    }
}

.createstask {
    ::v-deep(.needGo) {
        span {
            color: rgba(0, 0, 0, 0.45);
            cursor: pointer;
        }
    }
}

.addlistoperator {
    width: 300px;
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    text-indent: 15px;
    position: relative;
    cursor: pointer;

    .opertorimages {
        width: 14px;
        height: 14px;
        right: 16px;
        top: 7px;
        position: absolute;
    }

    .addoperator {
        width: 300px;
        position: absolute;
        top: 30px;
        z-index: 9999;
        border: 1px solid #dcdfe6;
        background: #fff;

        .modoals {
            max-height: 200px;
            overflow-y: auto;
            border-radius: 4px;
            z-index: 9999;

            .boxdiv {
                height: 36px;
                line-height: 36px;
            }
        }

        .addbtn {
            height: 36px;
            line-height: 36px;
        }
    }

    .arrowTransform {
        transition: 0.2s;
        transform-origin: center;
        transform: rotateZ(-180deg);
    }

    .arrowTransformReturn {
        transition: 0.2s;
        transform-origin: center;
        transform: rotateZ(0deg);
    }
}
</style>
